export enum PasswordResetActionTypes {
  PASSWORD_RESET_EMAIL_FIELD = 'PASSWORD_RESET_EMAIL_FIELD',
  PASSWORD_RESET_CODE_FIELD = 'PASSWORD_RESET_CODE_FIELD',
  PASSWORD_RESET_NEW_PASSWORD_FIELD = 'PASSWORD_RESET_NEW_PASSWORD_FIELD',
  PASSWORD_RESET_CONFIRM_NEW_PASSWORD_FIELD = 'PASSWORD_RESET_CONFIRM_NEW_PASSWORD_FIELD',

  RESET_PASSWORD_EMAIL_REQUEST = 'RESET_PASSWORD_EMAIL_REQUEST',
  RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS',
  RESET_PASSWORD_EMAIL_ERROR = 'RESET_PASSWORD_EMAIL_ERROR',

  SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_ERROR = 'SET_NEW_PASSWORD_ERROR',
}

// =========================================PASWORD RESET FIELDS========================================

export interface PasswordresetEmailField {
  type: PasswordResetActionTypes.PASSWORD_RESET_EMAIL_FIELD;
  passwordResetEmail: string;
}

export interface PasswordResetCodeField {
  type: PasswordResetActionTypes.PASSWORD_RESET_CODE_FIELD;
  passwordResetCode: string;
}

export interface PasswordResetNewPasswordField {
  type: PasswordResetActionTypes.PASSWORD_RESET_NEW_PASSWORD_FIELD;
  passwordResetNewPassword: string;
}

export interface PasswordResetConfirmNewPasswordField {
  type: PasswordResetActionTypes.PASSWORD_RESET_CONFIRM_NEW_PASSWORD_FIELD;
  passwordResetConfirmNewPassword: string;
}

export function passwordResetEmailField(passwordResetEmail: string): PasswordresetEmailField {
  return {
    type: PasswordResetActionTypes.PASSWORD_RESET_EMAIL_FIELD,
    passwordResetEmail,
  };
}

export function passwordResetCodeField(passwordResetCode: string): PasswordResetCodeField {
  return {
    type: PasswordResetActionTypes.PASSWORD_RESET_CODE_FIELD,
    passwordResetCode,
  };
}

export function passwordResetNewPasswordField(passwordResetNewPassword: string): PasswordResetNewPasswordField {
  return {
    type: PasswordResetActionTypes.PASSWORD_RESET_NEW_PASSWORD_FIELD,
    passwordResetNewPassword,
  };
}

export function passwordResetConfirmNewPasswordField(
  passwordResetConfirmNewPassword: string,
): PasswordResetConfirmNewPasswordField {
  return {
    type: PasswordResetActionTypes.PASSWORD_RESET_CONFIRM_NEW_PASSWORD_FIELD,
    passwordResetConfirmNewPassword,
  };
}

// ===================================RESET PASSWORD EMAIL=====================================

export interface ResetPasswordEmailRequest {
  type: PasswordResetActionTypes.RESET_PASSWORD_EMAIL_REQUEST;
  resetPasswordEmail: string;
}

export interface ResetPasswordEmailSuccess {
  type: PasswordResetActionTypes.RESET_PASSWORD_EMAIL_SUCCESS;
  resetPasswordSuccess: any;
}

export interface ResetPasswordEmailError {
  type: PasswordResetActionTypes.RESET_PASSWORD_EMAIL_ERROR;
  resetPasswordError: Error;
}

export function resetPasswordEmailRequest(resetPasswordEmail: string): ResetPasswordEmailRequest {
  return {
    type: PasswordResetActionTypes.RESET_PASSWORD_EMAIL_REQUEST,
    resetPasswordEmail,
  };
}

export function resetPasswordEmailSuccess(resetPasswordSuccess: any): ResetPasswordEmailSuccess {
  return {
    type: PasswordResetActionTypes.RESET_PASSWORD_EMAIL_SUCCESS,
    resetPasswordSuccess,
  };
}

export function resetPasswordEmailError(resetPasswordError: Error): ResetPasswordEmailError {
  return {
    type: PasswordResetActionTypes.RESET_PASSWORD_EMAIL_ERROR,
    resetPasswordError,
  };
}

// ===============================SET NEW PASSWORD===================================

export interface SetNewPasswordRequest {
  type: PasswordResetActionTypes.SET_NEW_PASSWORD_REQUEST;
  newPasswordEmail: string;
  newPasswordCode: string;
  newPassword: string;
}

export interface SetNewPasswordSuccess {
  type: PasswordResetActionTypes.SET_NEW_PASSWORD_SUCCESS;
  newPasswordSuccess: any;
}

export interface SetNewPasswordError {
  type: PasswordResetActionTypes.SET_NEW_PASSWORD_ERROR;
  newPasswordError: Error;
}

export function setNewPasswordRequest(
  newPasswordEmail: string,
  newPasswordCode: string,
  newPassword: string,
): SetNewPasswordRequest {
  return {
    type: PasswordResetActionTypes.SET_NEW_PASSWORD_REQUEST,
    newPasswordEmail,
    newPasswordCode,
    newPassword,
  };
}

export function setNewPasswordSuccess(newPasswordSuccess: any): SetNewPasswordSuccess {
  return {
    type: PasswordResetActionTypes.SET_NEW_PASSWORD_SUCCESS,
    newPasswordSuccess,
  };
}

export function setNewPasswordError(newPasswordError: Error): SetNewPasswordError {
  return {
    type: PasswordResetActionTypes.SET_NEW_PASSWORD_ERROR,
    newPasswordError,
  };
}

export type PasswordResetActions =
  | PasswordresetEmailField
  | PasswordResetCodeField
  | PasswordResetNewPasswordField
  | PasswordResetConfirmNewPasswordField
  | ResetPasswordEmailRequest
  | ResetPasswordEmailSuccess
  | ResetPasswordEmailError
  | SetNewPasswordRequest
  | SetNewPasswordSuccess
  | SetNewPasswordError;
