import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AppActions } from '../../../../../helpers/appActionTypes';
import {
  PasswordResetActionTypes,
  PasswordResetActions,
  ResetPasswordEmailRequest,
  resetPasswordEmailSuccess,
  resetPasswordEmailError,
  SetNewPasswordRequest,
  setNewPasswordSuccess,
  setNewPasswordError,
} from './action';


const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function resetPasswordEmailEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: PasswordResetActions) => action.type === PasswordResetActionTypes.RESET_PASSWORD_EMAIL_REQUEST),
    switchMap(({ resetPasswordEmail }: ResetPasswordEmailRequest) =>
      cognitoClient.forgotPassword(resetPasswordEmail).then(resetPasswordEmailSuccess).catch(resetPasswordEmailError),
    ),
  );
}

export function newPasswordEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: PasswordResetActions) => action.type === PasswordResetActionTypes.SET_NEW_PASSWORD_REQUEST),
    switchMap(({ newPasswordEmail, newPasswordCode, newPassword }: SetNewPasswordRequest) =>
      cognitoClient
        .confirmPassword(newPasswordEmail, newPasswordCode, newPassword)
        .then(setNewPasswordSuccess)
        .catch(setNewPasswordError),
    ),
  );
}
