import update from 'immutability-helper';
import { PasswordResetActionTypes, PasswordResetActions } from './action';

export interface PasswordResetState {
  passwordResetEmail: string;
  passwordResetCode: string;
  passwordResetNewPassword: string;
  passwordResetConfirmNewPassword: string;

  resetPasswordEmail: string;
  resetPasswordSuccess: any;
  resetPasswordError: Error;

  newPasswordEmail: string;
  newPasswordCode: string;
  newPassword: string;
  newPasswordSuccess: any;
  newPasswordError: Error;
}

export const InitialPasswordResetState: PasswordResetState = {
  passwordResetEmail: '',
  passwordResetCode: '',
  passwordResetNewPassword: '',
  passwordResetConfirmNewPassword: '',

  resetPasswordEmail: '',
  resetPasswordSuccess: null,
  resetPasswordError: null,

  newPasswordEmail: '',
  newPasswordCode: '',
  newPassword: '',
  newPasswordSuccess: null,
  newPasswordError: null,
};

const passwordResetReducer = (state = InitialPasswordResetState, action: PasswordResetActions) => {
  switch (action.type) {
    // =========================================PASWORD RESET FIELDS========================================

    case PasswordResetActionTypes.PASSWORD_RESET_EMAIL_FIELD:
      return update(state, {
        passwordResetEmail: { $set: action.passwordResetEmail },
      });
    case PasswordResetActionTypes.PASSWORD_RESET_CODE_FIELD:
      return update(state, {
        passwordResetCode: { $set: action.passwordResetCode },
      });
    case PasswordResetActionTypes.PASSWORD_RESET_NEW_PASSWORD_FIELD:
      return update(state, {
        passwordResetNewPassword: { $set: action.passwordResetNewPassword },
      });
    case PasswordResetActionTypes.PASSWORD_RESET_CONFIRM_NEW_PASSWORD_FIELD:
      return update(state, {
        passwordResetConfirmNewPassword: { $set: action.passwordResetConfirmNewPassword },
      });

    // ===================================RESET PASSWORD EMAIL=====================================

    case PasswordResetActionTypes.RESET_PASSWORD_EMAIL_REQUEST:
      return update(state, {
        resetPasswordEmail: { $set: action.resetPasswordEmail },
      });
    case PasswordResetActionTypes.RESET_PASSWORD_EMAIL_SUCCESS:
      return update(state, {
        resetPasswordSuccess: { $set: action.resetPasswordSuccess },
      });
    case PasswordResetActionTypes.RESET_PASSWORD_EMAIL_ERROR:
      return update(state, {
        resetPasswordError: { $set: action.resetPasswordError },
      });

    // ===============================SET NEW PASSWORD===================================

    case PasswordResetActionTypes.SET_NEW_PASSWORD_REQUEST:
      return update(state, {
        newPasswordEmail: { $set: action.newPasswordEmail },
        newPasswordCode: { $set: action.newPasswordCode },
        newPassword: { $set: action.newPassword },
      });
    case PasswordResetActionTypes.SET_NEW_PASSWORD_SUCCESS:
      return update(state, {
        newPasswordSuccess: { $set: action.newPasswordSuccess },
      });
    case PasswordResetActionTypes.SET_NEW_PASSWORD_ERROR:
      return update(state, {
        newPasswordError: { $set: action.newPasswordError },
      });
    default:
      return state;
  }
};

export default passwordResetReducer;
