import update from 'immutability-helper';
import { SingInActionTypes, SingInActions } from './action';

export interface SingInState {
  singInEmail: string;
  singInPassword: string;
  singInSuccessFul: any;
  singInFailedError: Error;
}

export const InitialSingInState: SingInState = {
  singInEmail: '',
  singInPassword: '',
  singInSuccessFul: null,
  singInFailedError: null,
};

const singInreducer = (state = InitialSingInState, action: SingInActions) => {
  switch (action.type) {
    case SingInActionTypes.SING_IN_EMAIL_FIELDS:
      return update(state, {
        singInEmail: { $set: action.singInEmail },
      });
    case SingInActionTypes.SING_IN_PASSWORD_FIELDS:
      return update(state, {
        singInPassword: { $set: action.singInPassword },
      });
    case SingInActionTypes.SING_IN_REQUEST:
      return update(state, {
        singInEmail: { $set: action.singInEmail },
        singInPassword: { $set: action.singInPassword },
      });
    case SingInActionTypes.SING_IN_SUCCESS:
      return update(state, {
        singInSuccessFul: { $set: action.singInSuccessFul },
      });
    case SingInActionTypes.SING_IN_ERROR:
      return update(state, {
        singInFailedError: { $set: action.singInFailedError },
      });
    default:
      return state;
  }
};

export default singInreducer;
