/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { getDevices } from '../devices/getDevices/redux/action';
import primiaryInterfaceRreducer from '../components/workspace/primaryInterface/redux/reducer';
import singInreducer from '../components/workspace/authComponents/singIn/redux/reducer';
import { singInEpic } from '../components/workspace/authComponents/singIn/redux/epic';
import registrationReducer from '../components/workspace/authComponents/registration/redux/reducer';
import {
  registrationCodeConfirmationEpic,
  registrationCridentialsEpic,
} from '../components/workspace/authComponents/registration/redux/epic';
import passwordResetReducer from '../components/workspace/authComponents/resetPassword/redux/reducer';
import {
  newPasswordEpic,
  resetPasswordEmailEpic,
} from '../components/workspace/authComponents/resetPassword/redux/epic';
/* Local dependencies */

const rootEpic = combineEpics(
  singInEpic,
  registrationCridentialsEpic,
  registrationCodeConfirmationEpic,
  resetPasswordEmailEpic,
  newPasswordEpic,
);

const rootReducer = combineReducers({
  getDevices,
  primiaryInterfaceRreducer,
  singInreducer,
  registrationReducer,
  passwordResetReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
