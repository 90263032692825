import Pages from '../../../../helpers/loginPages';

export enum PrimiaryInterfaceActionTypes {
  OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL',
  CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL',

  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  GO_TO_PREVIOUS_PAGE = 'GO_TO_PREVIOUS_PAGE',

  OPEN_LEADER_BOARD = 'OPEN_LEADER_BOARD',
  CLOSE_LEADER_BOARD = 'CLOSE_LEADER_BOARD',
}

export interface OpenAuthModal {
  type: PrimiaryInterfaceActionTypes.OPEN_AUTH_MODAL;
}

export interface CloseAuthModal {
  type: PrimiaryInterfaceActionTypes.CLOSE_AUTH_MODAL;
}

export interface SetCurrentPage {
  type: PrimiaryInterfaceActionTypes.SET_CURRENT_PAGE;
  stage: Pages;
}

export interface GoToPreviousPage {
  type: PrimiaryInterfaceActionTypes.GO_TO_PREVIOUS_PAGE;
}

export interface OpenLeaderBoard {
  type: PrimiaryInterfaceActionTypes.OPEN_LEADER_BOARD;
}

export interface CloseLeaderBoard {
  type: PrimiaryInterfaceActionTypes.CLOSE_LEADER_BOARD;
}

export function openAuthModal(): OpenAuthModal {
  return {
    type: PrimiaryInterfaceActionTypes.OPEN_AUTH_MODAL,
  };
}

export function closeAuthModal(): CloseAuthModal {
  return {
    type: PrimiaryInterfaceActionTypes.CLOSE_AUTH_MODAL,
  };
}

export function setCurrentPage(stage: Pages): SetCurrentPage {
  return {
    type: PrimiaryInterfaceActionTypes.SET_CURRENT_PAGE,
    stage,
  };
}
export function goToPreviousPage(): GoToPreviousPage {
  return {
    type: PrimiaryInterfaceActionTypes.GO_TO_PREVIOUS_PAGE,
  };
}

export function openLeaderBoard(): OpenLeaderBoard {
  return {
    type: PrimiaryInterfaceActionTypes.OPEN_LEADER_BOARD,
  };
}

export function closeLeaderBoard(): CloseLeaderBoard {
  return {
    type: PrimiaryInterfaceActionTypes.CLOSE_LEADER_BOARD,
  };
}

export type PrimiaryInterfaceActions =
  | OpenAuthModal
  | CloseAuthModal
  | SetCurrentPage
  | GoToPreviousPage
  | OpenLeaderBoard
  | CloseLeaderBoard;
