import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SingInActionTypes, SingInActions, SingInRequest, singInSuccess, singInError } from './action';
import { AppActions } from '../../../../../helpers/appActionTypes';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function singInEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: SingInActions) => action.type === SingInActionTypes.SING_IN_REQUEST),
    switchMap(({ singInEmail, singInPassword }: SingInRequest) =>
      cognitoClient.signIn(singInEmail, singInPassword).then(singInSuccess).catch(singInError),
    ),
  );
}
