import update from 'immutability-helper';
import { RegistrationActionTypes, RegistrationActions } from './action';

export interface RegistrationState {
  registrationEmail: string;
  registrationPassword: string;
  registrationConfirmPassword: string;
  registrationCode: string;

  registrationCridentialsEmail: string;
  registrationCridentialsPassword: string;
  registerCridentialsSuccess: any;
  registerCridentialsError: Error;

  registrationCodeConfirmationEmail: string;
  registrationCodeConfirmationCode: string;
  registrationSuccessFul: any;
  registrationFailedError: Error;
}

export const InitialRegistrationState: RegistrationState = {
  registrationEmail: '',
  registrationPassword: '',
  registrationConfirmPassword: '',
  registrationCode: '',

  registrationCridentialsEmail: '',
  registrationCridentialsPassword: '',
  registerCridentialsSuccess: undefined,
  registerCridentialsError: undefined,

  registrationCodeConfirmationEmail: '',
  registrationCodeConfirmationCode: '',
  registrationSuccessFul: null,
  registrationFailedError: null,
};

const registrationReducer = (state = InitialRegistrationState, action: RegistrationActions) => {
  switch (action.type) {
    // =============================== REGISTRATION_FIELDS ===============================

    case RegistrationActionTypes.REGISTRATION_EMAIL_FIELD:
      return update(state, {
        registrationEmail: { $set: action.registrationEmail },
      });
    case RegistrationActionTypes.REGISTRATION_PASSWORD_FIELD:
      return update(state, {
        registrationPassword: { $set: action.registrationPassword },
      });
    case RegistrationActionTypes.REGISTRATION_CONFIRM_PASSWORD_FIELD:
      return update(state, {
        registrationConfirmPassword: { $set: action.registrationConfirmPassword },
      });
    case RegistrationActionTypes.REGISTRATION_CODE_FIELD:
      return update(state, {
        registrationCode: { $set: action.registrationCode },
      });

    // =============================== REGISTRATION_CRIDENTIALS ===============================

    case RegistrationActionTypes.REGISTRATION_CRIDENTIALS_REQUEST:
      return update(state, {
        registrationCridentialsEmail: { $set: action.registrationCridentialsEmail },
        registrationCridentialsPassword: { $set: action.registrationCridentialsPassword },
      });
    case RegistrationActionTypes.REGISTRATION_CRIDENTIALS_SUCCESS:
      return update(state, {
        registerCridentialsSuccess: { $set: action.registerCridentialsSuccess },
      });
    case RegistrationActionTypes.REGISTRATION_CRIDENTIALS_ERROR:
      return update(state, {
        registerCridentialsError: { $set: action.registerCridentialsError },
      });

    // =============================== REGISTRATION_CODE_CONFIRMATIONS ===============================

    case RegistrationActionTypes.REGISTRATION_CODE_CONFIRMATION_REQUEST:
      return update(state, {
        registrationCodeConfirmationEmail: { $set: action.registrationCodeConfirmationEmail },
        registrationCodeConfirmationCode: { $set: action.registrationCodeConfirmationCode },
      });
    case RegistrationActionTypes.REGISTRATION_SUCCESS:
      return update(state, {
        registrationSuccessFul: { $set: action.registrationSuccessFul },
      });
    case RegistrationActionTypes.REGISTRATION_ERROR:
      return update(state, {
        registrationFailedError: { $set: action.registrationFailedError },
      });
    default:
      return state;
  }
};

export default registrationReducer;
