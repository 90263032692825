enum Pages {
  InitialPage = 'InitialPage',

  SingInPage = 'SingInPage',
  SingInLoadingPage = 'SingInLoadingPage',

  PasswordResetEmailPage = 'PasswordResetEmailPage',
  PasswordResetCodePage = 'PasswordResetCodePage',
  PasswordResetNewPasswordPage = 'PasswordResetNewPasswordPage',
  PasswordResetLoadingPage = 'PasswordResetLoadingPage',

  RegistrationEmailPage = 'RegistrationEmailPage',
  RegistrationPasswordPage = 'RegistrationPasswordPage',
  RegistrationCodePage = 'RegistrationCodePage',
  RegistrationLoadingPage = 'RegistrationLoadingPage',
}

export default Pages;
