export enum SingInActionTypes {
  SING_IN_EMAIL_FIELDS = 'SING_IN_EMAIL_FIELDS',
  SING_IN_PASSWORD_FIELDS = 'SING_IN_PASSWORD_FIELDS',
  SING_IN_REQUEST = 'SING_IN_REQUEST',
  SING_IN_SUCCESS = 'SING_IN_SUCCESS',
  SING_IN_ERROR = 'SING_IN_ERROR',
}

export interface SingInEmailFields {
  type: SingInActionTypes.SING_IN_EMAIL_FIELDS;
  singInEmail: string;
}

export interface SingInPasswordFields {
  type: SingInActionTypes.SING_IN_PASSWORD_FIELDS;
  singInPassword: string;
}

export interface SingInRequest {
  type: SingInActionTypes.SING_IN_REQUEST;
  singInEmail: string;
  singInPassword: string;
}

export interface SingInSuccess {
  type: SingInActionTypes.SING_IN_SUCCESS;
  singInSuccessFul: any;
}

export interface SingInError {
  type: SingInActionTypes.SING_IN_ERROR;
  singInFailedError: Error;
}

export function singInEmailFields(singInEmail: string): SingInEmailFields {
  return {
    type: SingInActionTypes.SING_IN_EMAIL_FIELDS,
    singInEmail,
  };
}

export function singInPasswordFields(singInPassword: string): SingInPasswordFields {
  return {
    type: SingInActionTypes.SING_IN_PASSWORD_FIELDS,
    singInPassword,
  };
}

export function singInRequest(singInEmail: string, singInPassword: string): SingInRequest {
  return {
    type: SingInActionTypes.SING_IN_REQUEST,
    singInEmail,
    singInPassword,
  };
}

export function singInSuccess(singInSuccessFul: any): SingInSuccess {
  return {
    type: SingInActionTypes.SING_IN_SUCCESS,
    singInSuccessFul,
  };
}

export function singInError(singInFailedError: Error): SingInError {
  return {
    type: SingInActionTypes.SING_IN_ERROR,
    singInFailedError,
  };
}

export type SingInActions = SingInEmailFields | SingInPasswordFields | SingInRequest | SingInSuccess | SingInError;
