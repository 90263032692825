import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import {
  RegistrationActionTypes,
  RegistrationActions,
  RegistrationCridentialsRequest,
  registrationCridentialsSuccess,
  registrationCridentialsError,
  RegistrationCodeConfirmationRequest,
  registrationSuccess,
  registrationError,
} from './action';
import { AppActions } from '../../../../../helpers/appActionTypes';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function registrationCridentialsEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter((action: RegistrationActions) => action.type === RegistrationActionTypes.REGISTRATION_CRIDENTIALS_REQUEST),
    switchMap(({ registrationCridentialsEmail, registrationCridentialsPassword }: RegistrationCridentialsRequest) =>
      cognitoClient
        .signUp(registrationCridentialsEmail, registrationCridentialsPassword)
        .then(registrationCridentialsSuccess)
        .catch(registrationCridentialsError),
    ),
  );
}

export function registrationCodeConfirmationEpic(action$): Observable<AppActions> {
  return action$.pipe(
    filter(
      (action: RegistrationActions) => action.type === RegistrationActionTypes.REGISTRATION_CODE_CONFIRMATION_REQUEST,
    ),
    switchMap(
      ({ registrationCodeConfirmationEmail, registrationCodeConfirmationCode }: RegistrationCodeConfirmationRequest) =>
        cognitoClient
          .signUpConfirmCode(registrationCodeConfirmationEmail, registrationCodeConfirmationCode)
          .then(registrationSuccess)
          .catch(registrationError),
    ),
  );
}
