export enum RegistrationActionTypes {
  REGISTRATION_EMAIL_FIELD = 'REGISTRATION_EMAIL_FIELD',
  REGISTRATION_PASSWORD_FIELD = 'REGISTRATION_PASSWORD_FIELD',
  REGISTRATION_CONFIRM_PASSWORD_FIELD = 'REGISTRATION_CONFIRM_PASSWORD_FIELD',
  REGISTRATION_CODE_FIELD = 'REGISTRATION_CODE_FIELD',

  REGISTRATION_CRIDENTIALS_REQUEST = 'REGISTRATION_CRIDENTIALS_REQUEST',
  REGISTRATION_CRIDENTIALS_SUCCESS = 'REGISTRATION_CRIDENTIALS_SUCCESS',
  REGISTRATION_CRIDENTIALS_ERROR = 'REGISTRATION_CRIDENTIALS_ERROR',

  REGISTRATION_CODE_CONFIRMATION_REQUEST = 'REGISTRATION_CODE_CONFIRMATION_REQUEST',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  REGISTRATION_ERROR = 'REGISTRATION_ERROR',
}

// =============================== REGISTRATION_FIELDS ===============================

export interface RegisrtationEmailField {
  type: RegistrationActionTypes.REGISTRATION_EMAIL_FIELD;
  registrationEmail: string;
}

export interface RegistrationPasswordField {
  type: RegistrationActionTypes.REGISTRATION_PASSWORD_FIELD;
  registrationPassword: string;
}

export interface RegistrationConfirmPasswordField {
  type: RegistrationActionTypes.REGISTRATION_CONFIRM_PASSWORD_FIELD;
  registrationConfirmPassword: string;
}

export interface RegistrationCodeField {
  type: RegistrationActionTypes.REGISTRATION_CODE_FIELD;
  registrationCode: string;
}

export function registrationEmailField(registrationEmail: string): RegisrtationEmailField {
  return {
    type: RegistrationActionTypes.REGISTRATION_EMAIL_FIELD,
    registrationEmail,
  };
}

export function registrationPasswordField(registrationPassword: string): RegistrationPasswordField {
  return {
    type: RegistrationActionTypes.REGISTRATION_PASSWORD_FIELD,
    registrationPassword,
  };
}

export function registrationConfirmPasswordField(
  registrationConfirmPassword: string,
): RegistrationConfirmPasswordField {
  return {
    type: RegistrationActionTypes.REGISTRATION_CONFIRM_PASSWORD_FIELD,
    registrationConfirmPassword,
  };
}

export function registrationCodeField(registrationCode: string): RegistrationCodeField {
  return {
    type: RegistrationActionTypes.REGISTRATION_CODE_FIELD,
    registrationCode,
  };
}

// =============================== REGISTRATION_CRIDENTIALS ===============================

export interface RegistrationCridentialsRequest {
  type: RegistrationActionTypes.REGISTRATION_CRIDENTIALS_REQUEST;
  registrationCridentialsEmail: string;
  registrationCridentialsPassword: string;
}

export interface RegistrationCridentialsSuccess {
  type: RegistrationActionTypes.REGISTRATION_CRIDENTIALS_SUCCESS;
  registerCridentialsSuccess: any;
}

export interface RegistrationCridentialsError {
  type: RegistrationActionTypes.REGISTRATION_CRIDENTIALS_ERROR;
  registerCridentialsError: Error;
}

export function registrationCridentialsRequest(
  registrationCridentialsEmail: string,
  registrationCridentialsPassword: string,
): RegistrationCridentialsRequest {
  return {
    type: RegistrationActionTypes.REGISTRATION_CRIDENTIALS_REQUEST,
    registrationCridentialsEmail,
    registrationCridentialsPassword,
  };
}

export function registrationCridentialsSuccess(registerCridentialsSuccess: any): RegistrationCridentialsSuccess {
  return {
    type: RegistrationActionTypes.REGISTRATION_CRIDENTIALS_SUCCESS,
    registerCridentialsSuccess,
  };
}

export function registrationCridentialsError(registerCridentialsError: Error): RegistrationCridentialsError {
  return {
    type: RegistrationActionTypes.REGISTRATION_CRIDENTIALS_ERROR,
    registerCridentialsError,
  };
}

// =============================== REGISTRATION_CODE_CONFIRMATIONS ===============================

export interface RegistrationCodeConfirmationRequest {
  type: RegistrationActionTypes.REGISTRATION_CODE_CONFIRMATION_REQUEST;
  registrationCodeConfirmationEmail: string;
  registrationCodeConfirmationCode: string;
}

export interface RegistrationSuccess {
  type: RegistrationActionTypes.REGISTRATION_SUCCESS;
  registrationSuccessFul: any;
}

export interface RegistrationError {
  type: RegistrationActionTypes.REGISTRATION_ERROR;
  registrationFailedError: Error;
}

export function registrationCodeConfirmationRequest(
  registrationCodeConfirmationEmail: string,
  registrationCodeConfirmationCode: string,
): RegistrationCodeConfirmationRequest {
  return {
    type: RegistrationActionTypes.REGISTRATION_CODE_CONFIRMATION_REQUEST,
    registrationCodeConfirmationEmail,
    registrationCodeConfirmationCode,
  };
}

export function registrationSuccess(registrationSuccessFul: any): RegistrationSuccess {
  return {
    type: RegistrationActionTypes.REGISTRATION_SUCCESS,
    registrationSuccessFul,
  };
}

export function registrationError(registrationFailedError: Error): RegistrationError {
  return {
    type: RegistrationActionTypes.REGISTRATION_ERROR,
    registrationFailedError,
  };
}

export type RegistrationActions =
  | RegisrtationEmailField
  | RegistrationPasswordField
  | RegistrationConfirmPasswordField
  | RegistrationCodeField
  | RegistrationCridentialsRequest
  | RegistrationCridentialsSuccess
  | RegistrationCridentialsError
  | RegistrationCodeConfirmationRequest
  | RegistrationSuccess
  | RegistrationError;
